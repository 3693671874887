/* eslint-disable react/require-default-props */
import classNames from "classnames"
import tw, { styled } from "twin.macro"

import Icons from "../v3/Icons"
import { IconsProps } from "../v3/Icons/Icons"

const textColors = {
    main: `text-main dark:text-dark-main`,
    bold: `text-bold dark:text-dark-bold`,
    additional: `text-additional dark:text-dark-additional`,
    disabled: `text-disabled dark:text-dark-disabled`,
    primary: `text-primary dark:text-dark-primary`,
    secondary: `text-secondary dark:text-dark-secondary`,
    "color-bg": `text-color-bg dark:text-dark-color-bg`,
    error: `text-error dark:text-dark-error`,
    warning: `text-warning dark:text-dark-warning`,
    success: `text-success dark:text-dark-success`,
    info: `text-info dark:text-dark-info`
}

export type TextColor = keyof typeof textColors

const variants = {
    gray: {
        circle: tw`bg-background-strong dark:bg-dark-background-strong`,
        iconColor: `text-icon-contrast dark:text-dark-icon-contrast`
    },
    teal: {
        circle: tw`bg-foreground-teal  dark:bg-dark-foreground-teal`,
        iconColor: `text-icon-primary dark:text-dark-icon-primary`
    },
    purple: {
        circle: tw`bg-foreground-purple dark:bg-dark-foreground-purple`,
        iconColor: `text-icon-secondary dark:text-dark-icon-secondary`
    },
    red: {
        circle: tw`bg-foreground-red dark:bg-dark-foreground-red`,
        iconColor: `text-icon-error dark:text-dark-icon-error`
    },
    yellow: {
        circle: tw`bg-foreground-yellow dark:bg-dark-foreground-yellow`,
        iconColor: `text-icon-warning dark:text-dark-icon-warning`
    },
    green: {
        circle: tw`bg-foreground-green dark:bg-dark-foreground-green`,
        iconColor: `text-icon-success dark:text-dark-icon-success`
    },
    blue: {
        circle: tw`bg-foreground-blue dark:bg-dark-foreground-blue`,
        iconColor: `text-icon-info dark:text-dark-icon-info`
    }
}

const sizes = {
    sm: tw`h-8 w-8`,
    md: tw`h-10 w-10`,
    lg: tw`h-12 w-12`,
    xl: tw`h-14 w-14`
}

export type Variant = keyof typeof variants
type Size = keyof typeof sizes

const defaultVariant: Variant = "teal"

const defaultSize: Size = "lg"

interface CircleProps {
    /**
     * Circle variant
     */
    variant?: Variant
    /**
     * Circle size
     */
    size?: Size
}

const Circle = styled.div<CircleProps>`
    ${tw`rounded-full flex justify-center items-center `};
    ${({ variant }) => variants[variant || defaultVariant].circle};
    ${({ size }) => sizes[size || defaultSize]};
`

interface FeaturedIconsProps extends React.PropsWithChildren<CircleProps> {
    /**
     * Class Name
     */
    className?: string
    /**
     * Icon
     */
    icon?: IconsProps["icon"]
    /**
     * Label Icons
     */
    label?: string
    /**
     * Label Size
     */
    labelSize?: 1 | 2 | 3 | 4 | 5 | 6
    /**
     * Label Position
     */
    labelColor?: TextColor
    /**
     * Label Position
     */
    labelPosition?: "bottom" | "right"
    /**
     * Circle Class Name
     */
    circleClassName?: string
    /**
    /**
     * onClick
     */
    onClick?: () => void
}

const Wrapper = styled.div<FeaturedIconsProps>`
    ${tw`gap-2 flex items-center w-fit`};
    ${({ labelPosition }) => labelPosition === "bottom" && tw`flex-col text-center`};
`

const getIconSize = (size: string) => {
    if (size === "sm") {
        return 16
    }
    if (size === "md") {
        return 20
    }
    if (size === "lg") {
        return 24
    }
    if (size === "xl") {
        return 28
    }
    return 24
}

const FeaturedIcons: React.FC<FeaturedIconsProps> = ({
    variant,
    size,
    icon,
    label,
    labelSize,
    labelColor,
    className,
    labelPosition,
    circleClassName,
    onClick
}: FeaturedIconsProps) => {
    const { iconColor } = variants[variant || defaultVariant]
    const iconSize = getIconSize(size || defaultSize)
    return (
        <Wrapper className={classNames("reku-new", className)} labelPosition={labelPosition} onClick={onClick}>
            <Circle variant={variant} size={size} className={classNames("reku-new", circleClassName)}>
                {icon && <Icons className={iconColor} icon={icon} width={iconSize} height={iconSize} />}
            </Circle>
            {label && (
                <span className={classNames(textColors[labelColor || "main"], `title-${labelSize}`)}>{label}</span>
            )}
        </Wrapper>
    )
}

FeaturedIcons.defaultProps = {
    icon: "Deposit",
    className: undefined,
    circleClassName: undefined,
    onClick: undefined,
    labelPosition: "right",
    label: undefined,
    labelSize: 5
}

export default FeaturedIcons
